import { useState, useEffect } from 'react';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { CategoriesTable } from '@/components/admin/categories/CategoriesTable';
import { CategoryForm } from '@/components/admin/categories/CategoryForm';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Helmet } from 'react-helmet-async';
import { supabase } from '@/integrations/supabase/client';
import type { CategoryData } from '@/components/admin/categories/categorySchema';
import { toast } from 'sonner';

export default function Categories() {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [categories, setCategories] = useState<CategoryData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchCategories = async () => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase
        .from('categories')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setCategories(data || []);
    } catch (error) {
      console.error('Error fetching categories:', error);
      toast.error('Erro ao carregar categorias');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className="p-8 bg-white min-h-screen">
      <Helmet>
        <title>Categorias - Painel Administrativo | Agência W3 Digital</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-3xl font-bold">Gerenciamento de Categorias</h1>
          <p className="text-gray-600 mt-2">
            Gerencie as categorias do blog
          </p>
        </div>
        <Button onClick={() => setIsCreateModalOpen(true)}>
          <Plus className="w-4 h-4 mr-2" />
          Nova Categoria
        </Button>
      </div>

      <CategoriesTable 
        categories={categories} 
        onDelete={fetchCategories} 
      />

      <Dialog open={isCreateModalOpen} onOpenChange={setIsCreateModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Nova Categoria</DialogTitle>
          </DialogHeader>
          <CategoryForm 
            onComplete={() => {
              setIsCreateModalOpen(false);
              fetchCategories();
            }} 
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}