import { useNavigate } from 'react-router-dom';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { PostsTable } from '@/components/admin/posts/PostsTable';
import { Helmet } from 'react-helmet-async';

export default function Blog() {
  const navigate = useNavigate();

  return (
    <div className="p-8 bg-white min-h-screen">
      <Helmet>
        <title>Blog - Painel Administrativo | Agência W3 Digital</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-3xl font-bold">Gerenciamento do Blog</h1>
          <p className="text-gray-600 mt-2">
            Gerencie os posts do blog
          </p>
        </div>
        <Button onClick={() => navigate('/admin/blog/new')}>
          <Plus className="w-4 h-4 mr-2" />
          Novo Post
        </Button>
      </div>

      <PostsTable />
    </div>
  );
}